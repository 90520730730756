import ValvestLogo from "@/public/logo_valvest.svg"
import translate from "@/utils/translation"
import { Link, useRouter } from "next-translate-routes"
import Image from "next/image"
import { ChevronDown, ChevronUp, X } from "react-bootstrap-icons"
import LanguageDropdown from "../language/languageDropdown"
import AccountDropdown from "../accountMenu/accountDropdown"
import styled from "styled-components"
import NotificationDropdown from "../notifications/notificationDropdown"

const MenuDesktop = ({ session }) => {
  const router = useRouter()

  function getLinkStyle(path) {
    return {
      color: router.pathname === path ? "rgb(251, 133, 0)" : "inherit",
    }
  }

  return (
    <Menu className="container inner d-none d-lg-block">
      <div className="row">
        <div className="col-4 col-md-2 col-xl-2 ">
          <Link href="/" className="clickable">
            <Image
              src={ValvestLogo}
              alt="valvest Logo"
              className="clickable"
              as="a"
              priority
              width={180}
            />
          </Link>
        </div>
        <div className="col-4 col-md-7 nav-items d-flex">
          <span className="nav-item clickable">
            <Link
              href={session?.uid ? "listings" : "/auth/register"}
              style={getLinkStyle("/listings")}
            >
              {translate("menu_listings")}
            </Link>
          </span>
          <span className="nav-item clickable">
            <Link href={"/how-it-works"} style={getLinkStyle("/helpcenter")}>
              {translate("menu_how")}
            </Link>
          </span>
          <span className="nav-item clickable">
            <Link
              href="/showcase"
              style={{
                color:
                  router.pathname === "/showcase"
                    ? "rgb(251, 133, 0)"
                    : "inherit",
              }}
            >
              {translate("menu_showcase")}
            </Link>
          </span>
          <span className="nav-item clickable">
            <Link href="/about-us" style={getLinkStyle("/about-us")}>
              {translate("menu_about")}
            </Link>
          </span>
        </div>
        {!session?.uid ? (
          <div className="col-5  col-md-3 nav-items d-none d-lg-flex justify-content-end">
            <LanguageDropdown />
            <span className="nav-item pe-4 clickable">
              <Link locale={router.locale} href="/auth/login" className="login">
                {translate("menu_login")}
              </Link>
            </span>
            <Link
              locale={router.locale}
              style={{
                marginBottom: "10px",
                borderRadius: "50px",
              }}
              href="/auth/register"
              className="mt-auto btn-lg btn-primary text-white clickable me-3"
            >
              {translate("menu_register")}
            </Link>
          </div>
        ) : (
          <div className="col-5 col-md-3 d-none d-lg-flex nav-items justify-content-end">
            <NotificationDropdown />
            <AccountDropdown session={session} />
          </div>
        )}
      </div>
    </Menu>
  )
}

export default MenuDesktop

const Menu = styled.div`
  .login {
    border: 1px solid #333;
    border-radius: 25px;
    :hover {
      border: 1px solid #8a8a8a;
    }
    padding: 0.65rem 1.125rem;
    color: #333;
  }

  .globe {
    margin: auto 0 auto 0;
  }
`
