import { Context } from "@/context/state"
import useComponentVisible from "@/components/common/useComponentVisible"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ChevronDown, Globe } from "react-bootstrap-icons"
import { useSession } from "next-auth/react"
import axios from "axios"
import { reloadSession } from "@/services/common/helpers"

const LanguageDropdown = () => {
  const [show, setShow] = useState(false)

  const { data: session } = useSession()

  const router = useRouter()

  const { state, dispatch } = useContext(Context)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const sessionLocale = session?.profile?.locale
      const currentLocale = state.locale
      const routerLocale = router?.locale

      if (sessionLocale && currentLocale !== sessionLocale) {
        dispatch({
          type: "SET_LOCALE",
          payload: sessionLocale,
        })
      } else if (!sessionLocale && !currentLocale) {
        dispatch({
          type: "SET_LOCALE",
          payload: routerLocale,
        })
      } else if (!sessionLocale && currentLocale !== routerLocale) {
        router.push(router.asPath, undefined, {
          locale: currentLocale,
        })
      }
    }
  }, [session, state.locale, router.locale])

  const updateUserLocale = async locale => {
    if (session) {
      const res = await axios
        .put("/api/users/locale", locale, {
          headers: {
            Authorization: `bearer ${session.jwt}`,
          },
        })
        .catch(error => {
          console.log(error)
        })

      if (res.status === 200) {
        reloadSession()

        dispatch({
          type: "SET_LOCALE",
          payload: locale.locale,
        })
      }
    }
  }

  return (
    <>
      <Item
        className="nav-item clickable country d-flex ms-3 pt-lg-3 pb-lg-3 pe-4"
        onClick={() => {
          setShow(!show)
        }}
      >
        <Globe className="me-0 me-md-2 globe" />
      </Item>
      <div
        className="position-relative"
        onClick={() => {
          setShow(!show)
        }}
      >
        {show && (
          <Language>
            <div className="active clickable">
              {state?.locale === "en"
                ? "English"
                : state?.locale === "nl"
                ? "Nederlands"
                : state?.locale === "es"
                ? "Español"
                : router?.locale === "en"
                ? "English"
                : "Nederlands"}
            </div>
            {router.locales
              .filter(item => item !== state?.locale)
              .map((locale, i) => (
                <div
                  key={i}
                  className="clickable"
                  onClick={() => {
                    setShow(false),
                      dispatch({
                        type: "SET_LOCALE",
                        payload: locale,
                      }),
                      updateUserLocale({ locale: locale })
                  }}
                >
                  {locale === "en"
                    ? "English"
                    : locale === "es"
                    ? "Español"
                    : "Nederlands"}
                </div>
              ))}
          </Language>
        )}
      </div>
    </>
  )
}

export default LanguageDropdown

const Language = styled.div`
  position: absolute;
  top: 55px;
  right: 5px;
  z-index: 9999;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.18);
  background: white;
  border-radius: 5px;

  div {
    padding: 0.875rem 1.25rem !important;
    font-size: 0.875rem;

    :hover {
      color: grey;
    }
  }

  .active {
    border-bottom: 1px solid #ddd;
    font-weight: 500;
  }
`

const Item = styled.div`
  .nav-item {
    :first-child {
      margin-left: 4rem;
    }
    padding: 1rem;
    padding-right: 3rem;
    margin: auto 0;
  }

  .country-name {
    color: #333;
  }
`
