import { Link, Router, useRouter } from "next-translate-routes"
import Image from "next/image"
import React, { useState } from "react"
import ValvestLogo from "@/public/logo_valvest.svg"
import MobileLanguage from "../language/mobileLanguage"
import MobileMenuSlideout from "./mobileMenuSlideout"
import { Avatar, Button } from "@mui/material"
import styled from "styled-components"
import AccountMobile from "../accountMenu/accountMobile"
import { RiQuestionnaireFill } from "react-icons/ri"
import translate from "@/utils/translation"
import LanguageDropdown from "../language/languageDropdown"
import NotificationDropdown from "../notifications/notificationDropdown"

const MenuMobile = ({ session }) => {
  const router = useRouter()
  const [showMobile, setMobile] = useState(false)
  const [showProfile, setProfile] = useState(false)

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "orange",
        fontSize: "1rem",
      },
      children: `${name?.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""}`,
    }
  }

  return (
    <Menu className="d-flex row d-lg-none container ps-2 pe-2 p-md-0">
      <div className="col-1 d-lg-none  d-flex nav-items justify-content-start">
        <div onClick={() => setMobile(!showMobile)} className="mobile-menu">
          <svg viewBox="0 0 100 100" width="20" height="20">
            <rect width="100" height="8"></rect>
            <rect y="30" width="100" height="8"></rect>
            <rect y="60" width="100" height="8"></rect>
          </svg>
        </div>
      </div>
      <MobileMenuSlideout
        showMobile={showMobile}
        setMobile={setMobile}
        session={session}
      />
      <AccountMobile
        showMobile={showProfile}
        setMobile={setProfile}
        session={session}
      />
      <div className="col-7">
        <Link href="/" className="clickable">
          <Image
            src={ValvestLogo}
            alt="valvest Logo"
            className="clickable"
            as="a"
            style={{ marginTop: "3px" }}
            priority
            width={160}
          />
        </Link>
      </div>
      {session?.profile ? (
        <div className="col-2 offset-2 d-flex justify-content-end pe-1">
          <NotificationDropdown />
          <div
            role="button"
            className="avatar"
            onClick={() => {
              setProfile(true)
            }}
          >
            <Avatar
              style={{
                height: "35px",
                width: "35px",
              }}
              {...stringAvatar(
                session?.profile?.firstName.toUpperCase() +
                  (session?.profile?.lastName || "").toUpperCase()
              )}
            />
          </div>
        </div>
      ) : (
        <div className="col-4 d-flex justify-content-end pe-0">
          <LanguageDropdown />
          {router.pathname.includes("/login") ? (
            <Link href="/auth/register" className="clickable mt-auto mb-auto">
              <Button
                variant="outlined"
                className="rounded-pill login"
                color="primary"
              >
                {translate("menu_register")}
              </Button>
            </Link>
          ) : (
            <Link href="/auth/login" className="clickable  mt-auto mb-auto">
              <Button
                variant="outlined"
                className="rounded-pill login"
                color="primary"
              >
                {translate("menu_login")}
              </Button>
            </Link>
          )}
        </div>
      )}
    </Menu>
  )
}

export default MenuMobile

const Menu = styled.div`
  margin: 0 auto;
  z-index: 1000;

  .avatar {
    margin-top: 0.5rem;
    border-radius: 50px;
    border: 1px solid rgba(18, 20, 23, 0.1) !important;
    padding: 3px;
    width: 43px;
    height: 43px;

    .MuiAvatar-root {
    }
  }

  .globe,
  .country-name {
    color: #777;
    margin: auto 0 auto 0;
  }

  .country {
    padding: 0 !important;
    margin-right: 1rem !important;
  }

  .login {
    margin-top: 0.5rem;
    padding: 0.5rem 1.5rem;
    height: 40px;
    margin: auto 0.25rem auto 0;
  }

  .help {
    margin-top: 0.9rem;
    margin-right: 1.5rem;

    svg {
      color: rgba(18, 20, 23, 0.75);

      :hover {
        color: rgba(18, 20, 23, 1) !important;
      }
    }
  }
`
