import { Copy, List, X } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link, useRouter } from "next-translate-routes"
import { Button, ClickAwayListener, Fade } from "@mui/material"
import styled from "styled-components"
import { signOut } from "next-auth/react"
import Image from "next/image"
import ValvestLogo from "@/public/logo_valvest.svg"
import { FaRegCopy } from "react-icons/fa"
import { toast } from "react-toastify"
import { FaUserFriends } from "react-icons/fa"
import {
  Bookmarks,
  BoxArrowLeft,
  CashStack,
  Gear,
  HouseCheck,
  Repeat,
} from "react-bootstrap-icons"

const MobileMenuSlideout = ({ showMobile, setMobile, session }) => {
  const router = useRouter()

  function getLinkStyle(path) {
    return {
      color: router.pathname === path ? "rgb(251, 133, 0)" : "inherit",
    }
  }

  return (
    <Fade in={showMobile}>
      <MobileMenu>
        <div
          className="inner mobile text-start"
          style={{
            left: showMobile ? 0 : "-100%",
            transition: "left 0.2s ease-in-out",
          }}
        >
          <div className="d-flex ms-3 me-3 mt-2">
            <Image
              src={ValvestLogo}
              alt="valvest Logo"
              className="clickable"
              as="a"
              priority
              style={{ marginTop: "2px" }}
              width={160}
              onClick={() => {
                router.push("/"), setMobile(false)
              }}
            />

            <X
              onClick={() => setMobile(false)}
              className="closeMobile"
              size={30}
              color={"#fb8500"}
            />
          </div>
          {!session?.profile && (
            <div className="p-4 buttons">
              <Button
                variant="contained"
                className="w-100"
                onClick={() => {
                  router.push("/auth/register"), setMobile(false)
                }}
              >
                {translate("menu_register")}
              </Button>
              <Button
                variant="outlined"
                className="w-100 mt-3"
                onClick={() => {
                  router.push("/auth/login"), setMobile(false)
                }}
              >
                {translate("menu_login")}
              </Button>
            </div>
          )}
          <div className="menu">
            <Link
              href={session?.uid ? "listings" : "/auth/register"}
              onClick={() => setMobile(false)}
            >
              <div className="item">{translate("menu_listings")}</div>
            </Link>
            <Link href={"/how-it-works"} onClick={() => setMobile(false)}>
              <div className="item">{translate("menu_how")}</div>
            </Link>
            <Link href="/showcase" onClick={() => setMobile(false)}>
              <div className="item">{translate("menu_showcase")}</div>
            </Link>
            <Link href="/about-us" onClick={() => setMobile(false)}>
              <div className="item">{translate("menu_about")}</div>
            </Link>
            <Link href="/helpcenter" onClick={() => setMobile(false)}>
              <div className="item">{translate("footer_business_faq")}</div>
            </Link>
          </div>
        </div>
      </MobileMenu>
    </Fade>
  )
}

export default MobileMenuSlideout

const MobileMenu = styled.div`
  background: rgba(0, 0, 0, 40%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999;
  text-align: left !important;

  .inner {
    width: 100%;
    min-width: 300px;
    background: white;
    position: fixed;
    top: 0px;
    height: 100%;
  }

  .menu .item {
    padding: 1rem 1.5rem !important;
    color: rgba(18, 20, 23, 0.75) !important;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .item-project {
    font-weight: 500;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .buttons {
    color: rgba(18, 20, 23, 0.75) !important;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;
  }

  svg {
    margin-right: 0.75rem;
  }

  .register {
    margin-top: 1rem;
    padding: 1rem 1.5rem 1rem !important;
    border-bottom: 1px solid #e6e6e6;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    cursor: pointer;

    a {
      color: rgba(18, 20, 23, 0.75) !important;
      font-weight: 500;
    }
  }

  .name {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .id {
    margin-top: 0;
    font-size: 0.9rem;
    color: rgba(18, 20, 23, 0.5) !important;
  }

  .closeMobile {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  hr {
    color: #a4a4a4;
  }
`
